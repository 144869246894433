@font-face {
  font-family: 'Roboto Slab';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/RobotoSlab-Regular.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto Bold';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'SignPainter';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/SignPainter-Regular.otf');
}

* {
  box-sizing: border-box;
}

a {
  color: #ED6337;
  text-decoration: none;
}


.container {
  margin: 0 auto;
  padding: 0 20px;
}

@media (min-width: 800px) {
  body {
    height: 600px;
    overflow-y: hidden;
  }
  .container {
    width: 800px;
    padding: 0 40px;
  }
}
